import React from "react"
import { Container, Header, List } from "semantic-ui-react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HowToCiteUsMessage from "../components/HowToCiteUsMessage"

const RelatedWorkPage = () => (
  <Layout>
    <SEO title="Related Work" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Header as="h1" dividing>
        <Header.Content>
          Related Work
          <Header.Subheader>
            Resources and Publications on Programming Misconceptions
          </Header.Subheader>
        </Header.Content>
      </Header>
      <HowToCiteUsMessage/>
      <p>
        Misconceptions have been studied in various areas of education for
        decades. In programming education, research in conceptual change and
        misconceptions is still less mature. Nevertheless, there are many
        publications that identify or describe various misconceptions related to
        computer science and programming.
      </p>
      <Header as="h2" dividing>
        Other Collections of Programming Misconceptions
      </Header>
      <p>
        There are some existing collections of programming misconceptions. Here
        are two that stand out:
      </p>
      <List selection verticalAlign="middle">
        <List.Item
          as="a"
          href="https://aaltodoc.aalto.fi/handle/123456789/3534"
        >
          <List.Icon name="database" verticalAlign="middle" />
          <List.Content>
            <List.Header>
              <span style={{ color: "#4183c4" }}>
                Juha Sorva's "Misconception Catalogue"
              </span>
            </List.Header>
            Appendix A of his Dissertation "Visual Program Simulation in
            Introductory Programming Education"
          </List.Content>
        </List.Item>
        <List.Item
          as="a"
          href="https://www.csteachingtips.org/browse-all?f%5B0%5D=tags%3AContent%20Misconceptions&submit=Apply"
        >
          <List.Icon name="database" verticalAlign="middle" />
          <List.Content>
            <List.Header>
              <span style={{ color: "#4183c4" }}>
                Colleen Lewis's "Content Misconceptions"
              </span>
            </List.Header>
            Part of csteachingtips.org
          </List.Content>
        </List.Item>
      </List>
      <p>
        These collections are excellent resources.{" "}
        <Link to="/misconceptions">Our collection</Link> differs in its
        organization and form. We organized our misconceptions by programming
        language <Link to="/concepts">concepts</Link>, we tied them to specific{" "}
        <Link to="/specs">programming languages</Link> and their features, to
        specific sections in <Link to="/textbooks">programming textbooks</Link>,
        and we related them to each other. Our misconceptions are thus
        structured in a tightly connected network.
      </p>
      <p>
        Moreover, we gave each misconception in our collection a unique and
        meaningful name, similar to the names used in various pattern languages.
        The form of each misconception includes a description as well as an
        explicit correction, and information to help teachers to detect and
        respond to the specific misconception.
      </p>

      <Header as="h2" dividing>
        Overviews of the Topic of Programming Misconceptions
      </Header>
      <p>
        Joha Sorva's chapter "Naive Conceptions of Novice Programmers" in{" "}
        <a href="https://www.shuchigrover.com/atozk12cs/">
          Computer Science in K-10: An A to Z handbook on teaching programming
        </a>
        , as well as Colleen Lewis, Michael Clancy, and Jan Vahrenhold's chapter
        "Student knowledge and misconceptions" in{" "}
        <a href="https://www.bookdepository.com/Cambridge-Handbook-Computing-Education-Research-Sally-Fincher/9781108721899">
          The Cambridge Handbook of Computing Education Research
        </a>{" "}
        provide an overview and introduction to the topic of programming
        misconceptions.
      </p>

      <Header as="h2" dividing>
        Studies Including Specific Programming Misconceptions
      </Header>
      <p>
        Our <a href="https://www.zotero.org/groups/progmiscon">Progmiscon Zotero group</a> provides a bibliography of relevant research papers.
      </p>
    </Container>
  </Layout>
)

export default RelatedWorkPage
